<template>
	<!-- 能源数据统计分析 -->
	<div class="energy_analysis">
		<!-- 头部 -->
		<div class="energy_head">
			<div class="select">
				<!-- 设备选择器 -->
				<el-cascader
				    v-model="value"
				    :options="options"
				    :props="{value: 'id', label: 'land_name',children:'children', expandTrigger: 'hover' }"
				    @change="handleChange"
					popper-class="train-tree"></el-cascader>
				 <el-date-picker
				    v-model="time"
				    type="date"
					:picker-options="pickerBeginDateBefore"
					value-format="timestamp"
					@change="handleTimeChange"
				    placeholder="选择日期">
				</el-date-picker>
			</div>
		</div>
		<!-- 中间部分 -->
		<div class="middle">
			<div class="title">
				<div class="t_l"></div>
				<div class="t_r">
					<div>储能设备</div>
					<div class="line">
						<div class="line1">
							<div class="line3"></div>
						</div>
						<div class="line2">
							<div class="line4"></div>
						</div>
					</div>
				</div>
			</div>
			<div class="m_data">
				<div class="data_item" v-for="(item,index) in list" :key='index'>
					<span style="margin-right: 10px;">{{item.type}}</span>
					<span class="number">{{item.num}}</span>
					<span class="number">{{item.dw}}</span>
				</div>
			</div>
		</div>
		<!-- 图表 -->
		<div class="charts_box">
			<div>
				<div class="chart_item">
					<div class="title">
						<div class="t_l bg2"></div>
						<div class="t_r">
							<div v-if="value[0]==1">24小时累计发电量</div>
							<div v-else-if="value[0]==2">24小时累计输入电量</div>
							<div v-else>24小时负荷</div>
							<div class="line">
								<div class="line1">
									<div class="line3"></div>
								</div>
								<div class="line2">
									<div class="line4"></div>
								</div>
							</div>
						</div>
					</div>
					<div id="myChart_energy_data_a1" ref='chart'></div>
					
				</div>
				<div class="chart_item">
					<div class="title">
						<div class="t_l bg2"></div>
						<div class="t_r">
							<div v-if="value[0]==1">24小时累计发电功率</div>
							<div v-else-if="value[0]==2">24小时累计输出电量</div>
							<div v-else>24小时设备运行功率</div>
							<div class="line">
								<div class="line1">
									<div class="line3"></div>
								</div>
								<div class="line2">
									<div class="line4"></div>
								</div>
							</div>
						</div>
					</div>
					<div id="myChart_energy_data_a2" ref='chart'></div>
				</div>
			</div>
			<div>
				<div class="chart_item" v-if="value[0]==2">
					<div class="title">
						<div class="t_l bg2"></div>
						<div class="t_r">
							<div >24小时设备运行功率</div>
							<div class="line">
								<div class="line1">
									<div class="line3"></div>
								</div>
								<div class="line2">
									<div class="line4"></div>
								</div>
							</div>
						</div>
					</div>
					<div id="myChart_energy_data_a3" ref='chart'></div>
				</div>
				<div class="chart_item">
					<div class="title">
						<div class="t_l bg2"></div>
						<div class="t_r">
							<div >24小时设备报警/故障次数</div>
							<div class="line">
								<div class="line1">
									<div class="line3"></div>
								</div>
								<div class="line2">
									<div class="line4"></div>
								</div>
							</div>
						</div>
					</div>
					<div id="myChart_energy_data_a4" ref='chart'></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getWordList,doWord } from '@/api/index'
// 引入所有 ECharts 中所有的图表和组件
import * as echarts from 'echarts';
export default {
  name: 'EnergyDataAnalysis',
  data () {
    return {
		time:'',
		pickerBeginDateBefore: {
		   disabledDate(time) {
		     return time.getTime() > Date.now();
		   }   
		},
		value:['1','11'],//默认选择的内容
		options:[
					 {
						id: '1',
						  land_name: '发电设备',
						  children: [{
						    id: '11',
						    land_name: '1号光伏'
						  }, {
						    id: '12',
						    land_name: '2号光伏'
						  }, {
						    id: '13',
						    land_name: '3号光伏'
						  }, {
						    id: '14',
						    land_name: '4号光伏'
						  }]
						},
						{
						   id: '2',
						     land_name: '储能设备',
						     children: [{
						       id: '21',
						       land_name: '1号储能设备'
						     }, {
						       id: '22',
						       land_name: '2号储能设备'
						     }]
						},
						{
						   id: '3',
						     land_name: '配电设备',
						     children: [{
						       id: '31',
						       land_name: '1号配电设备'
						     }, {
						       id: '32',
						       land_name: '2号配电设备'
						     }, {
						       id: '33',
						       land_name: '3号配电设备'
						     }
							 ]
						} ,
						{
						   id: '4',
						     land_name: '充电桩',
						     children: [{
						       id: '41',
						       land_name: '1号充电桩'
						     }, {
						       id: '42',
						       land_name: '2号充电桩'
						     }, {
						       id: '43',
						       land_name: '3号充电桩'
						     }
							 ]
						}       
		],
		objectList:['24小时累计输入电量','24小时累计输出电量','24小时设备运行功率','24小时设备报警/故障次数'],
		list:[
			{type:'24小时累计发电量',num:'2382',dw:'Kwh'},
			{type:'24小时累计发电功率',num:'2382',dw:'Kw'},
			{type:'24小时设备报警/故障次数',num:'4',dw:'次'}
		],
		list1:[
			{type:'24小时累计发电量',num:'1210',dw:'Kwh'},
			{type:'24小时累计发电功率',num:'1210',dw:'Kw'},
			{type:'24小时设备报警/故障次数',num:'4',dw:'次'}
		],
		list2:[
			{type:'24小时累计输入电量',num:'1210',dw:'Kwh'},
			{type:'24小时累计输出电量',num:'1210',dw:'Kwh'},
			{type:'24小时设备运行功率',num:'1210',dw:'Kw'},
			{type:'24小时设备报警/故障次数',num:'4',dw:'次'}
		],
		list3:[
			{type:'24小时负荷',num:'1210',dw:'V'},
			{type:'24小时设备运行功率',num:'1210',dw:'Kw'},
			{type:'24小时设备报警/故障次数',num:'4',dw:'次'}
		],
		// list4:[
		// 	{type:'24小时负荷',num:'20',dw:'kwh'},
		// 	{type:'24小时设备运行功率',num:'20',dw:'kwh'},
		// 	{type:'24小时设备报警/故障次数',num:'20',dw:'kwh'}
		// ],
		xAxisName:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24],
		data1:[89,198,290,360,439,459,498,548,595,635,660,700,719,732,759,799,849,904,952,1012,1081,1000,1121,1210],
		data2:[89,198,290,360,439,459,498,548,595,635,660,700,719,732,759,799,849,904,952,1012,1081,1000,1121,1210],
		data3:[89,198,290,360,439,459,498,548,595,635,660,700,719,732,759,799,849,904,952,1012,1081,1000,1121,1210],
		data4:[0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,2,0,0,0,0,1,0,0],
		data5:[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
		timeId:[],//定时器集合
	 }
	},
	mounted () {
		  console.log(111)
	  // 加载数据表格
	  // this.getProfile(1)
		this.drawLine('myChart_energy_data_a1','Kwh',['rgba(54,207,201,1)','rgba(54,207,201,0.2)'],this.data1,false,1)
		this.drawLine('myChart_energy_data_a2','Kwh',['rgba(237,152,69,1)','rgba(237,152,69,0.2)'],this.data2,false,2)
		this.drawLine2()
		// this.drawLine3()
		// this.drawLine4()
	},
	destroyed() {
		console.log('destroyed')
		console.log(this.timeId)
		// 清空该页面中所有的的定时器
		for(let i=0;i<this.timeId.length;i++){
		   clearInterval(this.timeId[i])
		}	
		// 销毁图表实列，释放echarts资源
		echarts.dispose(this.myChart1);
		echarts.dispose(this.myChart2);
		this.myChart1 = null;
		this.myChart2 = null;
	},
	methods:{
		// 设备选择框事件
		handleChange(value) {
		    console.log(value);
			if(value[0]=='1'){
				this.list=this.list1
			}else if(value[0]=='2'){
				this.list=this.list2
				console.log(22)
				this.$forceUpdate()
				this.$nextTick(function(){
					console.log('hahh')
					this.drawLine('myChart_energy_data_a3','Kwh',['rgba(125,62,211,1)','rgba(125,62,211,0.2)'],this.data3,true,3)
				})
			}else{
				this.list=this.list3
			}
			
			this.$forceUpdate()
		},
		// 时间选择器
		handleTimeChange(e){
			console.log(e)
		},
		// 封装图表方法
		drawLine(idName,yName,colors,dataList,isSmooth,num) {
				   // 基于准备好的dom，初始化echarts实例
				           this.myChart1 = echarts.init(document.getElementById(idName));
				           this.myChart1.resize({
				             width: this.$refs.chart.clientWidth,
				             height: this.$refs.chart.clientHeight
				           })
				           // 指定图表的配置项和数据
				           var option = {
						    animationDuration: 10000,
						    grid: {
						        left: '1%',
						        right: '5%',
						        bottom: '5%',
								top:40,
						        containLabel: true
						    },
						    
						    tooltip: {
						        show: "true",
						        trigger: 'axis',
						        axisPointer: { // 坐标轴指示器，坐标轴触发有效
						            type: 'cross' // 默认为直线，可选为：'line' | 'shadow'
						        },
								backgroundColor:'#0B1326',//通过设置rgba调节背景颜色与透明度
								borderColor:'gray',
								textStyle:{
									align:'left',///标签内容左对齐
									color: '#FFFFFF'
								},
						    },
							 toolbox: {
							   feature: {
							 	magicType: { type: ['line', 'bar'] },
							     saveAsImage: {
							 		show:true,
							         backgroundColor :'balck',
							 		pixelRatio: 3
							 	}
							   },
							 },
						    yAxis: {
						        name:yName, //kwh
						        nameTextStyle: {
						            fontSize: 12,
						            fontFamily: 'Microsoft YaHei',
						            color: '#fff'
						        },
						        type: 'value',
						        axisTick: {
						            show: false
						        },
						        axisLine: {
						            show: true,
						            lineStyle: {
						                color: '#535A6B',
						            },
						        },
						        splitLine: {
						            show: false,
						            lineStyle: {
						                color: '#FFFFFF',
						            }
						        },
								axisLabel: {
								    fontSize: 12,
								    fontFamily: 'Microsoft YaHei',
								    color: "#FFFFFF",
								},
						    },
						    xAxis: [
						        {
						            type: 'category',
						            axisTick: {
						                show: false
						            },
						            axisLine: {
						                show: true,
						                lineStyle: {
						                    color: '#535A6B',
						                }
						            },
						            axisLabel: {
						            fontSize: 12,
						            fontFamily: 'Microsoft YaHei',
						            color: "#FFFFFF",
						        },
						            data: this.xAxisName
						        }
						    
						    ],
						    series: [
						        {
						            name: '该小时累计数量',
						            type: 'line',
									smooth: isSmooth,
									areaStyle: {
										//改变区域渐变颜色
										normal: {
										    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
										            offset: 0,
										            color: colors[0]
										        }, {
										            offset: 1,
										            color: colors[1]
										        }]),            
										}									
									},
						            itemStyle: {
						                normal: {
						                    show: true,
						                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
						                        offset: 0,
						                        color: colors[0]
						                    }, {
						                        offset: 1,
						                        color: colors[1]
						                    }]),
						                    barBorderRadius: 50,
						                    borderWidth: 0,
						                    borderColor: '#333',
						                }
						            },
						            label: {
						                normal: {
						                    show: true,
						                    position: 'top',
						                    textStyle: {
						                        color: '#fff'
						                    }
						                }
						            },
						            barWidth: '20%',
						            data:dataList,
								    markPoint: {
								          data: [
								            { type: 'max', name: 'Max' },
								            { type: 'min', name: 'Min' }
								          ]
								        },
								   markLine: {
								       data: [{ type: 'average', name: 'Avg' }]
								   }
						        }
						    
						    ]
						   
						   
				           };
				           // 使用刚指定的配置项和数据显示图表。
				           this.myChart1.setOption(option);
						   // //动态循环显示数据节点
						   this.dataLableChange(this.myChart1,option,num)
							   
		},
		drawLine2() {
				   // 基于准备好的dom，初始化echarts实例
				           this.myChart2 = echarts.init(document.getElementById('myChart_energy_data_a4'));
				           this.myChart2.resize({
				             width: this.$refs.chart.clientWidth,
				             height: this.$refs.chart.clientHeight
				           })
				           // 指定图表的配置项和数据
				           var option = {
						    animationDuration: 10000,
						    grid: {
						        left: '1%',
						        right: '5%',
						        bottom: '5%',
								top:40,
						        containLabel: true
						    },
							color: ['#45C3E2','#E2CA45',],
						    legend: {
						      data: ['报警次数', '故障次数'],
						      textStyle:{
						      	  color: '#FFFFFF'
						      },
						      // orient: 'vertical',  //垂直显示
						      y: '10',    //延Y轴居中
						      x: 'center' ,//居右显示
						      itemWidth:30,//图例的宽度
						      itemHeight:2,//图例的高度
						    },
						    tooltip: {
						        show: "true",
						        trigger: 'axis',
						        axisPointer: { // 坐标轴指示器，坐标轴触发有效
						            type: 'cross' // 默认为直线，可选为：'line' | 'shadow'
						        },
								backgroundColor:'#0B1326',//通过设置rgba调节背景颜色与透明度
								borderColor:'gray',
								textStyle:{
									align:'left',///标签内容左对齐
									color: '#FFFFFF'
								},
						    },
							toolbox: {
							  feature: {
								magicType: { type: ['line', 'bar'] },
							    saveAsImage: {
									show:true,
							        backgroundColor :'balck',
									pixelRatio: 3
								}
							  },
							},
						    yAxis: {
						        name:'次数', //kwh
						        nameTextStyle: {
						            fontSize: 12,
						            fontFamily: 'Microsoft YaHei',
						            color: '#fff'
						        },
						        type: 'value',
						        axisTick: {
						            show: false
						        },
						        axisLine: {
						            show: true,
						            lineStyle: {
						                color: '#535A6B',
						            },
						        },
						        splitLine: {
						            show: false,
						            lineStyle: {
						                color: '#FFFFFF',
						            }
						        },
								axisLabel: {
								    fontSize: 12,
								    fontFamily: 'Microsoft YaHei',
								    color: "#FFFFFF",
								},
						    },
						    xAxis: [
						        {
						            type: 'category',
						            axisTick: {
						                show: false
						            },
						            axisLine: {
						                show: true,
						                lineStyle: {
						                    color: '#535A6B',
						                }
						            },
						            axisLabel: {
						            fontSize: 12,
						            fontFamily: 'Microsoft YaHei',
						            color: "#FFFFFF",
						        },
						            data: this.xAxisName
						        }
						    
						    ],
						    series: [
						        {
						            name: '报警次数',
									smooth: true,
						            type: 'line',
									areaStyle: {
										//改变区域渐变颜色
										normal: {
										    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
										            offset: 0,
										            color: 'rgba(69,195,226,1)'
										        }, {
										            offset: 1,
										            color: 'rgba(69,195,226,0.2)'
										        }]),            
										}									
									},
						            itemStyle: {
						                normal: {
						                    show: true,
						                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
						                        offset: 0,
						                        color: 'rgba(69,195,226,1)'
						                    }, {
						                        offset: 1,
						                        color: 'rgba(69,195,226,0.2)'
						                    }]),
						                    barBorderRadius: 50,
						                    borderWidth: 0,
						                    borderColor: '#333',
						                }
						            },
						            label: {
						                normal: {
						                    show: true,
						                    position: 'top',
						                    textStyle: {
						                        color: '#fff'
						                    }
						                }
						            },
						            barWidth: '20%',
						            data:this.data4,
								    markPoint: {
								          data: [
								            { type: 'max', name: 'Max' },
								            { type: 'min', name: 'Min' }
								          ]
								        },
								   markLine: {
								       data: [{ type: 'average', name: 'Avg' }]
								   }
						        },
								{
								    name: '故障次数',
								    type: 'line',
									smooth: true,
									areaStyle: {
										//改变区域渐变颜色
										normal: {
										    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
										            offset: 0,
										            color: 'rgba(226,202,69,1)'
										        }, {
										            offset: 1,
										            color: 'rgba(226,202,69,0.2)'
										        }]),            
										}									
									},
								    itemStyle: {
								        normal: {
								            show: true,
								            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
								                offset: 0,
								                color: 'rgba(226,202,69,1)'
								            }, {
								                offset: 1,
								                color: 'rgba(226,202,69,0.2)'
								            }]),
								            barBorderRadius: 50,
								            borderWidth: 0,
								            borderColor: '#333',
								        }
								    },
								    label: {
								        normal: {
								            show: true,
								            position: 'top',
								            textStyle: {
								                color: '#fff'
								            }
								        }
								    },
								    barWidth: '20%',
								    data:this.data5,
								    markPoint: {
								          data: [
								            { type: 'max', name: 'Max' },
								            { type: 'min', name: 'Min' }
								          ]
								        },
								   markLine: {
								       data: [{ type: 'average', name: 'Avg' }]
								   }
								}
						    
						    ]
						   
						   
				           };
				           // 使用刚指定的配置项和数据显示图表。
				           this.myChart2.setOption(option);
						   // //动态循环显示数据节点
						   this.dataLableChange(this.myChart2,option,4)
							   
		},
		// 动态循环显示数据节点方法
		dataLableChange(myChart,option,i){
				   // console.log('shenme')
				   //动态循环显示数据节点
				   var app = {
				       currentIndex: -1,
				   };
				   this.timeId[i]=setInterval(function() {
				   	   // console.log('start'+i)
				       var dataLen = option.series[0].data.length;
				       // 取消之前高亮的图形
				       myChart.dispatchAction({
				           type: 'downplay',
				           seriesIndex: 0,
				           dataIndex: app.currentIndex
				       });
				       app.currentIndex = (app.currentIndex + 1) % dataLen;
				   
				       // 高亮当前图形
				       myChart.dispatchAction({
				           type: 'highlight',
				           seriesIndex: 0,
				           dataIndex: app.currentIndex,
				       });
				   
				       // 显示 tooltip
				       myChart.dispatchAction({
				           type: 'showTip',
				           seriesIndex: 0,
				           dataIndex: app.currentIndex
				       });
				   
				   }, 5000); 
				   
		},
	}
	
}
</script>

<style scoped="scoped">
.energy_analysis{
	width: 100%;
	height: 100%;
	padding:0 30px 30px 30px;
	box-sizing: border-box;
	overflow: auto;
}
.energy_head{
	  width: 100%;
	  height: 78px;
	  margin-bottom: 30px;
	 display: flex;
	  align-items:flex-end;
	  justify-content: start;
	  position: relative;
  }
  .select{
	  width: 553px;
	  height: 48px;
	  /* background-color: #1CCA81; */
	  display: flex;
	 align-items:center;
	 justify-content: start;
  }
  .middle{
	  width: 100%;
	  height:102px ;
	  background-color: rgba(10,23,56,0.7);
	  padding:0 20px ;
	  box-sizing: border-box;
  }
  .title{
      width: 100%;
      height: 42px;
      display: flex;
      align-items: flex-end;
      aljustify-content: start;
      text-align: left;
  	  margin-top: 0;
  	  margin-bottom: 2%;
    }
    .t_l{
    	  width: 33px;
    	  height: 34px;
    	 background: url(../../assets/jiankong/quan_o.png);
    	 background-size: contain;
    	  margin-right: 14px;
    }
	.bg2{
		  background: url(../../assets/jiankong/quan_b.png); 
		  background-size: contain;
	}
    .t_r{
    	  width: calc(100% - 47px);
    	  font-size: 16px;
    	  font-family: PingFang SC;
    	  font-weight: bold;
    	  color: #BFF2F0;
    	  height: 42px;
    	  line-height: 42px;
    }
    .line{
    	  width: 100%;
    	  height: 1px;
    	  display: flex;
  	  justify-content: space-between;
  	  align-items: center;
    }
    .line1{
  	  width: 84%;
  	  height: 1px;
  	  background: #535A6B;
    }
    .line2{
  	  width: 15%;
  	  height: 1px;
  	  background: #535A6B;
  	  text-align: center;
    }
    .line3{
  	width: 90%;
  	height: 1px; 
  	  background: #36CFC9;
    }
    .line4{
  	  position: relative;
  	  left: 50%;
  	  transform: translateX(-50%);
  	  width: 15px;
  	  height: 1px;
  	  background: #36CFC9;
    }
	.m_data{
		margin-top: -10px;
		display: flex;
		align-items: center;
		justify-content: space-around;
	}
	.data_item{
		font-size: 16px;
		font-family: PingFang SC;
		/* font-weight: bold; */
		color: #BFF2F0;
	}
	.number{
		font-weight: bold;
		color: #FFFFFF;
	}
	.charts_box{
		width: 100%;
		height: calc(100% - 242px);
		margin-top: 30px;
		/* background-color: red; */
	}
	.charts_box>div{
		height: calc(50% - 15px);
		margin-bottom: 30px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.chart_item{
		width: calc(50% - 15px);
		height: 100%;
		background-color: rgba(10,23,56,0.7);
		padding: 0 20px;
		box-sizing: border-box;
	}
	#myChart_energy_data_a1{
		width: 100%;
		height:calc(100% - 50px);
	}
	#myChart_energy_data_a2{
		width: 100%;
		height:calc(100% - 50px);
	}
	#myChart_energy_data_a3{
		width: 100%;
		height:calc(100% - 50px);
	}
	#myChart_energy_data_a4{
		width: 100%;
		height:calc(100% - 50px);
	}
 /deep/.el-cascader .el-input .el-input__inner{
 	  height: 48px;
 	  /* width: 553px; */
 	  background-color: rgba(11,19,38,0.5);
 	  border: 1px solid #36CFC9;
 	  color: #FFFFFF !important;
 }
/deep/.el-date-editor .el-range__icon{
	  color: #FFFFFF!important;
	  margin-top: 5px;
  }
  /deep/.el-date-editor.el-input, .el-date-editor.el-input__inner{
	  background-color: transparent;
	  color: #FFFFFF !important;
	  margin-left: 30px;
	  
  }
  /deep/.el-input--prefix .el-input__inner{
  	 border: 1px solid #36CFC9;
  	 height: 48px;
	 margin-top: 0;
	 background-color: rgba(11,19,38,0.5);
	 color: #FFFFFF !important;
  }
  /deep/.el-input__icon{
	   color: #FFFFFF !important;
	   margin-top: 2px;
  }
</style>
